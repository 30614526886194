import React from "react";
import { Drawer, Button, Typography, Card, Box } from "@mui/material";
import usePWAInstallPrompt from "./PwaInstall";
import { Height } from "@mui/icons-material";

const InstallPWA = () => {
  const { deferredPrompt, isIOS, showPrompt, setShowPrompt } = usePWAInstallPrompt();

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log("User choice:", outcome);
      setShowPrompt(false);
    }
  };

  return (
    <Drawer
    
    anchor="bottom" open={showPrompt} onClose={() => setShowPrompt(false)}>
      <Box style={{ padding: "5px", textAlign: "center" }}>
        {isIOS ? (
          <Card>
            <Typography variant="h6">Install T-TRADES App</Typography>
            <Typography variant="body2">
              Open Safari, tap the <b>Share</b> button, and select <b>"Add to Home Screen"</b>.
            </Typography>
          </Card>
        ) : (
          <Card>
            <Typography variant="h6">Install our App</Typography>
            <Button variant="contained" onClick={handleInstallClick}>Install</Button>
          </Card>
        )}
      </Box>
    </Drawer>
  );
};

export default InstallPWA;
