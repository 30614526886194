import { useEffect, useState } from "react";

const usePWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    // Detect if the device is iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
    setIsIOS(/iphone|ipad|ipod/.test(userAgent) && !isStandalone);

    // Listen for the beforeinstallprompt event (Android)
    const handler = (e) => {
      e.preventDefault(); // Prevent the default prompt
      setDeferredPrompt(e);
      setShowPrompt(true); // Show MUI drawer
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  return { deferredPrompt, isIOS, showPrompt, setShowPrompt };
};

export default usePWAInstallPrompt;
